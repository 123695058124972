<template>
  <div class="contact-map">
    <div class="map-select">
      <button type="button" v-for="loc in locations" :key="loc.key" :class="location == loc.key ? 'selected':''" @click="selectMap(loc.key)">{{loc.location}}</button>
    </div>
    <div v-if="location == 'ko'" class="map">
      <vue-daum-map
        :appKey="daumMap.appKey"
        :center="daumMap.center"
        :level="daumMap.level"
        :mapTypeId="daumMap.mapTypeId"
        :markerImageURL="daumMap.markerImageURL"
        :libraries="daumMap.libraries"
        @load="onLoadMap"
        style="width: 100%;height:480px;">
      </vue-daum-map>
      <div class="map-marker"></div>
    </div>
    <div v-if="location == 'ph'" class="map">
      <iframe class="i-google-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7721.353269846526!2d121.09598521565142!3d14.617487777830581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b83f03dae891%3A0xe3ed980d39ecb2ea!2sIl%20Centro!5e0!3m2!1sen!2sph!4v1724306473698!5m2!1sen!2sph"
        width="100%" height="480" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
    <div class="contact-pane animate__animated animate__fadeInRight">
      <div class="panes">
        <div class="pane">
          <div class="pane-title">Get in touch</div>
          <div class="pane-body" v-html="address"></div>
        </div>
        <div class="pane">
          <div class="pane-title">Phone</div>
          <div class="pane-body" v-html="phone"></div>
        </div>
        <div class="pane">
          <div class="pane-title">Contact</div>
          <div class="pane-body" v-html="email"></div>
        </div>
      </div>
      <div class="buttons">
        <div class="pane-button">
          <img src="/assets/icons/download.png" alt="">
          <span>회사소개서</span>
        </div>
        <div class="pane-button">
          <img src="/assets/icons/translate.png" alt="">
          <span>번역가 지원</span>
        </div>
        <div class="pane-button">
          <img src="/assets/icons/stacks.png" alt="">
          <span>입사 지원</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueDaumMap from 'vue-daum-map'
import store from '@/store'

export default {
  props: {
    location: String
  },
  components: {
    VueDaumMap
  },
  data: () => ({
    locations: [
      {
        key: 'ko',
        location: 'Seoul, Korea'
      },
      {
        key: 'ph',
        location: 'Manila, Philippines'
      }
    ],
    daumMap: {
      appKey: 'd63edae48a358264d6b916708a95000d',
      level: 3,
      center: {
        lat: 37.5002186,
        lng: 127.0962874
      },
      mapTypeId: VueDaumMap.MapTypeId.NORMAL,
      markerImageURL: 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png',
      libraries: []
    }
  }),
  computed: {
    address: function () {
      if (this.location == 'ph') {
        return '2F iL Centro,<br>Sta. Lucia East Grand Mall,<br>San Isidro, Cainta, Rizal'
      }
      return '서울시 송파구 삼학사로 33<br>(삼전동 182-13) 4F'
    },
    phone: function () {
      if (this.location == 'ph') {
        // todo
      }
      return '070-8270-3640'
    },
    email: function () {
      if (this.location == 'ph') {
        // todo
      }
      return '@textree.co.kr'
    }
  },
  methods: {
    selectMap: function (loc) {
      if (loc == 'ko') {
        store.commit('SET_MORE_CURRENT_INDEX', 1)
      }
      if (loc == 'ph') {
        store.commit('SET_MORE_CURRENT_INDEX', 2)
      }
    },
    onLoadMap: function (map, daum) {
      console.log(map)
      console.log(daum)
    }
  }
}
</script>
<style scoped>
div.contact-map div.map {
  position: relative;
}
div.map-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/assets/icons/map-marker.png');
  background-size: 48px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10000;
}

div.map-select {
  margin-top: 64px;
  margin-bottom: 24px;
}

button {
  width: 256px;
  height: 64px;
  background: #F1F1F1;
  color: #959595;
  border: none;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
}

button {
  width: 256px;
  height: 64px;
  background: #F1F1F1;
  color: #959595;
  border: none;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
}
button.selected {
  background: #008ED6;
  color: #ffffff;
}

div.contact-map > div:not(.map-select) {
  display: block;
  max-width: 100%;
  overflow: hidden;
}

div.contact-pane {
  display: none !important;
}

@media(max-width: 1024px) {
  div.map-select {
    margin-top: 24px;
  }
}

@media(max-width: 768px) {
  div.map-select {
    display: flex;
  }
  div.map-select button {
    width: 50%;
  }
}

@media(max-width: 576px) {
  div.contact-map div.map {
    margin: 0 -24px;
    width: calc(100% + 24px + 24px);
    max-width: calc(100% + 24px + 24px);
  }
  div.map iframe {
    height: 280px;
  }

  div.contact-pane {
    background: #008ED6;
    padding: 24px;
    margin: 0 -24px;
    width: calc(100% + 24px + 24px);
    display: flex !important;
  }
  div.pane-title {
    font-size: 18px;
    font-family: "Montserrat";
    font-weight: bold;
    color: white;
    margin-bottom: 8px;
  }
  div.pane-body {
    font-size: 13px;
    font-family: "Montserrat";
    font-weight: normal;
    color: white;
    margin-bottom: 12px;
  }

  div.pane-button {
    width: calc(100% - 24px - 24px);
    border: 1px solid #F1F1F1;
    border-radius: 3px;
    font-size: 18px;
    font-family: "Montserrat";
    line-height: 22px;
    padding: 12px 24px;
    color: white;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }
  div.pane-button img {
    width: 24px;
    margin-right: 8px;
  }
  div.panes {
    padding-bottom: 12px;
    flex: 0 0 calc(50% - 12px);
    margin-right: 12px;
  }
  div.buttons {
    flex: 0 0 50%;
  }
}
</style>
